import React, { useEffect } from 'react';
import { getUrlParams } from '../../helpers/location';
import * as Api from '../../apis';
import Divider from '@material-ui/core/Divider';
import MainLayout from '../../components/MainLayout';
import QuestionnaireList from '../../components/QuestionnaireList';
import BlogList from '../../components/BlogList';


const QuestionnaireListPage = ({ location }) => {
  const query = getUrlParams(location.search)
  const { user_question_id = '', category_id = '' } = query;

  useEffect(() => {
    Api.postCountData('questionnaires', user_question_id);
  }, []);

  return (
    <MainLayout>
      <QuestionnaireList categoryId={category_id} />
      <Divider />
      <BlogList categoryId={category_id} />
    </MainLayout>
  );
}

export default QuestionnaireListPage;
