import React, { useState, useEffect } from 'react'
import * as Api from '../../apis';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';
import BlogCard from '../BlogCard';

const BlogList = ({ categoryId = '' }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    Api.getBlogs(`category_id=${categoryId}`).then(result => {
      setBlogs(result.results)
    });
  }, []);

  return (
    <>
      <h2>บทความที่เกี่ยวข้อง</h2>
      <Grid container>
        {blogs.map(blog => {
          return (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <Box p={1.5}>
                <BlogCard
                  title={blog.topic}
                  detail={blog.detail}
                  image={blog.cover.small}
                  onClick={() => navigate(`/blog/${blog.id}`)}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
}

export default BlogList;
